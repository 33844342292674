import { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import MobileSidebar from './MobileSidebar';
import DesktopSidebar from './DesktopSidebar';
import SearchProfile from './SearchProfile';
import User from './User.js';

import {
  HomeIcon,
  TableCellsIcon,
  ServerIcon,
  DocumentTextIcon,
  CommandLineIcon,
  WrenchIcon,
  SignalIcon
} from '@heroicons/react/24/outline';

const navItems = [
  [
    {
      name: 'Dashboard',
      to: '/',
      icon: HomeIcon,
      activePath: '/',
      mobile: true,
    },
    {
      name: 'Runners',
      to: '/runners',
      icon: ServerIcon,
      activePath: '/runners',
      mobile: true,
    },
    {
      name: 'Build queue',
      to: '/queue',
      icon: TableCellsIcon,
      activePath: '/queue',
      mobile: true,
    },
    {
      name: 'Insights',
      to: '/reports',
      icon: DocumentTextIcon,
      activePath: '/reports/*',
      mobile: true,
    },
    {
      name: 'Events',
      to: '/events',
      icon: SignalIcon,
      activePath: '/events',
      mobile: true,
    },
  ],
  [
    {
      name: 'CLI',
      to: '/cli',
      icon: CommandLineIcon,
      activePath: '/cli',
      mobile: false,
    },
    {
      name: 'SSH Sessions',
      to: '/ssh-sessions',
      icon: WrenchIcon,
      activePath: '/ssh-sessions',
      mobile: true,
    },
  ],
];

function Base(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const cookies = new Cookies();

  useEffect(() => {
    setInterval(() => {
      let token = cookies.get('actuated_dashboard');
      if (!token) {
        window.location.reload();
      }
    }, 60 * 1000);
  });

  let user = new User();
  const innerComponent = props.Component;

  return (
    <>
      <div>
        <MobileSidebar
          // navItems={navItems.filter((item) => item.mobile)}
          navItems={navItems}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
        ></MobileSidebar>

        <DesktopSidebar navItems={navItems}></DesktopSidebar>

        <SearchProfile
          user={user}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          Main={innerComponent}
        ></SearchProfile>
      </div>
    </>
  );
}

export default Base;
