export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export function changePercentage(current, preceding) {
  if (preceding == 0) {
    return current == 0 ? 0 : 100
  }
  return Math.round((100 * (current - preceding)) / preceding);
}

export function nanoToMin(nano) {
  const minuteNanos = 60000000000;

  return nano / minuteNanos;
}
